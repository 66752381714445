.sl-entrance-block {
    --margin-top: var(--bazis-margin-8x);
    --margin-bottom: var(--bazis-margin-8x);
    --margin-start: auto;
    --margin-end: auto;
    --padding-top: 40px;
    --padding-bottom: 40px;
    --width: 394px;
    --box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    max-width: 100%;

    .bazis-block__header {
        --margin-bottom: var(--bazis-margin-4x);
    }

    .bazis-btn-group {
        width: 100%;
        margin-top: var(--bazis-margin-8x);

        &__item {
            width: 50%;

            // возможно перенесем в файл bazis-btn-group, если везде будет шрифт 13
            > bazis-button.bazis-btn_solid {
                font-size: 13px;
            }
        }
    }

    .bazis-control {
        margin-top: var(--bazis-margin-4x);

        &__label {
            letter-spacing: 0.5px;
        }

        &.sl-symbol-control {
            margin-bottom: var(--bazis-margin-6x);
        }
    }

    app-input-symbol + .error {
        position: relative;
        top: -20px;
    }

    p:not(.bazis-control p, bazis-display-error p) {
        margin-bottom: var(--bazis-margin-8x);
    }

    h4,
    p,
    bazis-button {
        width: 100%;
    }

    hr {
        margin: var(--bazis-margin-6x) auto var(--bazis-margin-4x);
        background: var(--bazis-border-color);
    }

    fieldset {
        margin-top: var(--bazis-margin-3x);
        border: 0;
        border-top: 1px solid var(--bazis-border-color);
    }

    legend {
        padding: 0 var(--bazis-padding-2x);
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        color: var(--bazis-text-color-secondary);
    }

    form {
        .sl-forgotten-pass {
            font-weight: 400;
            line-height: 20px;
        }
    }

    @media (max-width: 576px) {
        --margin-bottom: 0;
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);
        --width: 100%;
        --border-color: transparent;
        --box-shadow: none;

        &:not(.bazis-modal .sl-entrance-block) {
            --margin-top: 0;
        }

        h4 {
            text-align: left;
        }

        bazis-button {
            --height: 44px;
        }
    }
}
