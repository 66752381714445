bazis-toolbar {
    & + .bazis-list_short-info {
        --margin-bottom-list: var(--bazis-margin-4x);
    }

    app-account-history & {
        gap: var(--bazis-margin-4x);
    }

    @media (max-width: 576px) {
        app-account-history &:not(.sl-card-mobile bazis-toolbar),
        app-account-reservation &:not(.sl-card-mobile bazis-toolbar) {
            &::part(start),
            &::part(end) {
                width: 100%;
            }
        }
    }
}

// все типы панелей инструментов с переопределяющими переменными компонента сетки
.sl-toolbar {
    &_table {
        --padding-top: var(--bazis-padding-7x);
        --margin-bottom: var(--bazis-margin-5x);
        flex-wrap: wrap;

        &::part(end) {
            margin-top: var(--bazis-margin-5x);
        }

        [slot='end'] {
            justify-content: flex-end;
        }

        app-bid & {
            --padding-top: 0;
        }

        .bazis-btn-group {
            margin: 0 var(--bazis-margin-8x) 0 var(--bazis-margin-4x)x;

            &:last-child {
                margin-right: 0;
            }
        }

        .sl-form & {
            --margin-top: var(--bazis-margin-3x);
            --padding-top: 14px;
            --padding-bottom: var(--bazis-padding-3x);
        }

        @media (max-width: 1024px) {
            &::part(start),
            &::part(end) {
                width: 100%;
                flex-shrink: 0;
            }
        }
    }

    &_table-header {
        --margin-top: calc(var(--bazis-margin-1x) * -1);
        --padding-bottom: var(--bazis-padding-4x);

        .bazis-dropdown {
            --top: 24px;
            --padding-start: var(--bazis-padding-3x);
            --padding-end: var(--bazis-padding-3x);
            --padding-top: var(--bazis-padding-4x);
            --padding-bottom: var(--bazis-padding-4x);
            --margin-start-list: calc(var(--bazis-padding-3x) * -1);
            --margin-end-list: calc(var(--bazis-padding-3x) * -1);

            bazis-button.bazis-dropdown__trigger {
                --color: var(--bazis-placeholder-color);
            }
        }
    }

    &_section {
        --margin-bottom: var(--bazis-margin-4x);

        @media (max-width: 1024px) {
            flex-wrap: wrap;

            &::part(start),
            &::part(end) {
                width: 100%;
            }
            &::part(start) {
                margin-bottom: var(--margin-bottom);
            }
        }
    }

    &_details {
        .bh-line-elements {
            --gap: var(--bazis-margin-1x);

            bazis-button bazis-icon,
            .bazis-dropdown bazis-button.bazis-dropdown__trigger bazis-icon {
                --bazis-icon-s-size: 20px;
                font-size: 20px;
            }
        }
    }

    &_notification {
        --padding-bottom: var(--bazis-padding-5x);
        --border-width: 0 0 1px;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
    }

    @media (max-width: 1024px) {
        &_table-header {
            --margin-top: 0;
            --padding-top: var(--bazis-padding-1x);
            --padding-bottom: var(--bazis-padding-1x);
            --padding-start: var(--bazis-padding-3x);
            --padding-end: var(--bazis-padding-1x);
            --border-width: 1px;
            --border-style: solid;
            --border-color: var(--bazis-border-color);

            .bazis-dropdown {
                --width: 250px;
            }
        }

        &_notification {
            --border-width: 0;
        }
    }

    @media (max-width: 576px) {
        &_notification {
            flex-direction: column;

            .bazis-select-date-control,
            &::part(start),
            &::part(end) {
                width: 100%;
            }

            .bh-line-elements {
                flex-direction: column;
            }
        }

        &_table-header {
            .bazis-checkbox__label {
                align-items: center;
            }
        }
    }
}