.bazis-dropdown {

    bazis-button.bazis-dropdown__trigger,
    &__trigger bazis-button,
    a.bazis-dropdown__trigger,
    &__trigger a {

        bazis-icon {
            --bazis-icon-xl-size: 32px;
        }
    }

    &.sl-dropdown_action {
        --top: 28px;
        --width: 210px;
        --color: var(--bazis-text-color-secondary);
        float: right;

        .bazis-list_select {
            border: var(--border-width-list) var(--border-style-list) var(--border-color-list);
        }
    }

    .bazis-list_vline & {
        --color: var(--bazis-text-color-secondary);
    }

    &.sl-dropdown_notifications {
        --width: 484px;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;

        .bazis-dropdown__body {
            // transform: translateX(calc(var(--width) / 2 - 18px));
            display: none;

            &[data-show] {
                display: block;
            }
        }

        .bazis-dropdown__trigger bazis-badge {
            top: 2px;
            right: -2px;
        }

        @media (max-width: 576px) {
            --width: 100%;
            --border-radius: 0;
            position: static;

            .bazis-dropdown__body {
                top: 8px !important;
            }
        }
    }

    &.sl-dropdown_settings-filter {
        --top: 36px;
        --left: 0;
        --width: 295px;
        --padding-top: var(--bazis-padding-3x);
        --padding-bottom: var(--bazis-padding-3x);

        bazis-button.bazis-dropdown__trigger {
            --background: var(--bazis-color-info-tint);
            --color: var(--bazis-color-base);
        }

        .bazis-control__label {
            margin-bottom: var(--bazis-margin-3x);

            & + .bazis-radio {
                margin-top: 0;
                margin-bottom: var(--bazis-margin-4x);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @media (max-width: 992px) {
            .bazis-dropdown__body {
                --top: auto;
                --left: 8px;
                --right: 8px;
                --bottom: 8px;
                --width: calc(100% - 16px);
                position: fixed;
                z-index: 1001;

                hr {
                    display: none;

                    + bazis-button {
                        --height: 44px;
                        display: block;
                    }
                }
            }
        }
    }
}
