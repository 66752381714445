.sl-form {
    .bazis-map-wrapper {
        --height: 324px;
    }

    app-bid-order & {
        padding-bottom: calc(var(--bazis-padding-6x) * 2);
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: var(--bazis-padding-5x);
        padding-bottom: var(--bazis-padding-5x);
        margin-top: calc(var(--bazis-margin-6x) * 2);
        background: var(--bazis-background);
        border-top: 1px solid var(--bazis-border-color);
        gap: var(--bazis-margin-4x);

        &::after,
        &::before {
            display: none;
        }

        app-bid-footer & {
            margin-top: 0;
            box-shadow: 0px -15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);
        }

        &__right {
            display: flex;
            align-items: center;
        }
    }

    &_oneline {
        display: flex;
        gap: 16px;

        .bazis-control__label {
            line-height: 36px;
        }

        &__full {
            width: 100%;
        }
    }

    @media (max-width: 576px) {
        &__footer {
            flex-direction: column-reverse;

            app-bid-footer & {
                flex-direction: column;
            }

            .bh-line-elements:not([slot]) {
                flex-direction: column;
                width: 100%;
            }
            &_finish {
                flex-direction: column;
                position: fixed;
                z-index: 40;
                bottom: 0;
                left: 0;
                right: 0;
            }
            &__right {
                flex-direction: column-reverse;
                width: 100%;

                > * {
                    width: 100%;
                }
            }
            bazis-button {
                display: block;
                width: 100%;
            }

            bazis-toolbar {
                width: 100%;
                flex-direction: column;

                &::part(start),
                &::part(end) {
                    width: 100%;
                }

                [slot].bh-line-elements {
                    flex-direction: column;
                }
            }
            bazis-entity-transits bazis-toolbar {
                gap: var(--bazis-margin-4x);
            }
        }

        &_oneline {
            flex-direction: column;
            gap: var(--bazis-margin-2x);

            .bazis-control__label {
                margin-bottom: 0;
                line-height: 20px;
            }

            bazis-button {
                margin-top: var(--bazis-margin-2x);
            }
        }
    }
}

.sl-eraser_outer {
    position: absolute;
    z-index: 1;
    right: 0;
    top: -8px;
}

.sl-loader-form {
    height: calc(100vh - 320px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
