.sl-filter__base {
    @media (max-width: 1024px) {
        .bazis-control {
            --margin-bottom: var(--bazis-margin-4x);
        }
    }

    @media (min-width: 1024.98px) {
        .bazis-control {
            --margin-bottom: 0;
            --height: 56px;
            --border-width: 0;
            --border-radius: 0;
            --padding-field-start: 20px;
            --color-label: var(--bazis-text-color-secondary);
            --font-size-label: 16px;
            --font-size: 16px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                height: var(--height);
                width: 1px;
                background: var(--bazis-border-color);
            }

            &__label {
                position: absolute;
                top: 50%;
                bottom: 8px; // подобрано, необходимо для анимации метки в селекте
                transform: translateY(-50%);
                left: var(--padding-field-start);
                z-index: 1;
                margin: 0;
                // line-height: var(--height);
                pointer-events: none;
                transition: bottom 0.2s ease-in;
            }

            &__field-group {
                & > .bazis-control__field {
                    top: -7px;
                }

                .bazis-control__field {
                    font-weight: 500;
                    line-height: 24px;
                    transition: top 0.2s ease-in;
                }
            }

            &--focused {
                .bazis-control__label {
                    // opacity: 0;
                }
                .bazis-control__field-group > .bazis-control__field {
                    top: 0;
                }
            }

            &--dirty,
            &_datetime .bazis-control--focused {
                .bazis-control__label {
                    --font-size-label: 11px;
                    top: auto;
                    bottom: 10px;
                    transform: translateY(0);
                    line-height: 1;
                    font-weight: 400;
                }

                &.bazis-control--focused { // ???? в чем разница классов?
                    .bazis-control__label {
                        bottom: 8px;
                    }
                }
            }
        }

        .bazis-select {
            --padding-start: var(--bazis-padding-5x);
            --border-radius: 0;

            &__trigger {
                height: calc(100% - 14px);
            }

            .bazis-control__field {
                --padding-field-start: 0;
                height: calc(100% - 14px);
            }
        }

        .bazis-select-native,
        .bazis-select {
            top: 7px;
            background: transparent;
        }

        filter {
            &:first-child {
                .bazis-control {
                    --border-radius: var(--bazis-border-radius) 0 0 var(--bazis-border-radius);
                    --padding-field-start: var(--bazis-padding-3x);

                    &::before {
                        display: none;
                    }
                }

                .bazis-select {
                    --padding-start: var(--bazis-padding-3x);
                }
            }
        }

        .bazis-list_control-dd {
            --position-top: 56px;
        }
    }
}
